
export const portfolioData = [
	{
		title: "Real Estate Website",
		techStack: ["CSS", "EmailJS", "App Engine", "HTML", "React"],
		// description: "Website built for real estate corporation Sure & Pure LLC. Features an image carousel, " +
		// 		"descriptive property panels and contact form.",
		description: "",
		imgSrc: "https://i.imgur.com/ZRjsbRc.jpg",
		imgAlt: "Preview of a real estate website.",
		accessLink: "https://www.sureandpurellc.com"
	},
	{
		title: "Coding Bootcamp Website",
		techStack: ["CSS", "App Engine", "HTML", "JavaScript", "JDoodle", "React", "Spring Boot"],
		description: "",
		imgSrc: "https://i.imgur.com/Ff3WKru.jpg",
		imgAlt: "Preview of a coding bootcamp website.",
		accessLink: "https://www.justcoffeecoder.com"
	},
	{
		title: "GPT-3 Chatbot",
		techStack: ["CSS", "HTML", "App Engine", "JavaScript", "Node.js", "OpenAI"],
		description: "",
		imgSrc: "https://i.imgur.com/IQKWxLl.jpg",
		imgAlt: "Preview of a chatroom using OpenAI's GPT-3 machine-learning language model.",
		accessLink: "https://portfolio.openaichatbot.stevenddao.com"
	},
	{
		title: "GamePigeon WordHunt Solver",
		techStack: ["CSS", "HTML", "App Engine", "React", "TypeScript"],
		description: "Coming soon...",
		imgSrc: "https://i.imgur.com/tkdBT1n.jpg",
		imgAlt: "Preview of a word game solver based on GamePigeon's Word Hunt.",
		accessLink: ""
	},
	{
		title: "Search Engine",
		techStack: ["CSS", "App Engine", "HTML", "React", "Spring Boot"],
		description: "Coming soon...",
		imgSrc: "https://i.imgur.com/tkdBT1n.jpg",
		imgAlt: "Preview of a search engine web interface.",
		accessLink: ""
	},
	{
		title: "MERN AI Image Generator",
		techStack: ["Cloudinary", "Express.js", "HTML", "MongoDB", "Node.js", "OpenAI", "React", "Tailwind CSS"],
		description: "Coming soon...",
		imgSrc: "https://i.imgur.com/ojhirBd.jpg",
		imgAlt: "Preview of an AI image generator web interface.",
		accessLink: "https://portfolio.aiimggenerator.stevenddao.com"
	}
];
