
import {useEffect} from "react";
import TagCloud from "TagCloud";

export default function TextSphere() {
	useEffect(() => {
		const container = ".tagcloud";
		const texts = [
				"HTML",
			"CSS",
			"Java",
			"Spring Boot",
			"JavaScript",
			"TypeScript",
			"SPFx",
			"React.js",
			"Python",
			"C",
			"C#",
			"MySQL",
			"Docker",
			"gcloud"
		];
		const options = {
			// radius in px
			radius: 350,
			// animation speed
			// slow, normal, fast
			maxSpeed: "fast",
			initSpeed: "normal",
			// 135 = right-bottom
			direction: 135,
			// interact with cursor move on mouse out
			keep: true
		};
		TagCloud(container, texts, options);
	});

	return (
			<div className={"text-sphere"}>
				{/* span tag className must be ".tagcloud" */}
				<span className={"tagcloud"}></span>
			</div>
	);
}